import React from "react";
import "./podcasts.css";
import { Typography } from "@mui/material";
import { podcastDetails } from "../components/podcastData";
import { useEffect } from "react";

const client_ID = "f2b442824af64bb1a5021e8b9ec1e919";
const client_secret = "0208bb7fc0bf407e84dbc9cba90ed70f";

const Podcasts = () => {
    useEffect(() => {
        var authParameters = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body:
                "grant_type=client_credentials&client_id=" +
                client_ID +
                "&client_secret=" +
                client_secret,
        };
        fetch("https://accounts.spotify.com/api/token", authParameters)
            .then((result) => result.json())
            .then((data) => console.log(data));
    }, []);

    return (
        <div>
            <div className="header podcastPg">
                <div></div>
                <Typography
                    variant="h1"
                    color="white"
                    sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        textTransform: "uppercase",
                    }}
                >
                    Podcast
                </Typography>

                <Typography
                    variant="h5"
                    color="white"
                    sx={{ fontFamily: "Space Mono" }}
                >
                    Listen to our podcast series to learn about various topics.
                </Typography>
            </div>

            <div className="spotifyCard">
                {podcastDetails.map((podcast) => (
                    <iframe
                        key={podcast.id}
                        src={podcast.source}
                        width={"30%"}
                        height="152"
                        frameBorder="0"
                        allowtransparency="true"
                        allow="encrypted-media"
                        title={podcast.id}
                        className="podcastIframe"
                    ></iframe>
                ))}
            </div>
        </div>
    );
};

export default Podcasts;
