import Path from "../img/path.jpg";
import studyTable from "../img/studyTable.jpg";
import worksheets from "../img/worksheets.jpg";

export const cardDetails = [
  {
    head: "Study Guides",
    description:
      "Targeted guides with key concepts, practice questions, and answer explanations for various subjects and exams.",
    image: Path,
  },
  {
    head: "Test Materials",
    description:
      "Exam anxiety? No sweat! Master your next test with smart strategies and powerful resources. Go crush it!",
    image: studyTable,
  },
  {
    head: "Worksheets",
    description:
      "Dive deeper into specific topics with our diverse collection of downloadable worksheets.",
    image: worksheets,
  },
  {
      head: "Study Guides",
      description:
        "Targeted guides with key concepts, practice questions, and answer explanations for various subjects and exams.",
      image: Path,
    },
    {
      head: "Test Materials",
      description:
        "Exam anxiety? No sweat! Master your next test with smart strategies and powerful resources. Go crush it!",
      image: studyTable,
    },
    {
      head: "Worksheets",
      description:
        "Dive deeper into specific topics with our diverse collection of downloadable worksheets.",
      image: worksheets,
    },
    {
      head: "Study Guides",
      description:
        "Targeted guides with key concepts, practice questions, and answer explanations for various subjects and exams.",
      image: Path,
    },
    {
      head: "Test Materials",
      description:
        "Exam anxiety? No sweat! Master your next test with smart strategies and powerful resources. Go crush it!",
      image: studyTable,
    },
    {
      head: "Worksheets",
      description:
        "Dive deeper into specific topics with our diverse collection of downloadable worksheets.",
      image: worksheets,
    },
    {
      head: "Study Guides",
      description:
        "Targeted guides with key concepts, practice questions, and answer explanations for various subjects and exams.",
      image: Path,
    },
    {
      head: "Test Materials",
      description:
        "Exam anxiety? No sweat! Master your next test with smart strategies and powerful resources. Go crush it!",
      image: studyTable,
    },
    {
      head: "Worksheets",
      description:
        "Dive deeper into specific topics with our diverse collection of downloadable worksheets.",
      image: worksheets,
    },
];