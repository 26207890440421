import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material'
import React from 'react'
import exam from '../img/exam.jpg'
import essay from '../img/essays.jpg'
import vocabulary from '../img/vocabulary.jpg'

const articles = [
      {
        id: 1,
        title: "5 Tips to Ace Your Math Exam",
        image: exam,
        excerpt: "Feeling nervous about your upcoming math exam? Don't worry, we've got you covered! Check out these 5 effective tips to help you prepare and achieve your best score.",
      },
      {
        id: 2,
        title: "Mastering Essay Writing: A Step-by-Step Guide",
        image: essay,
        excerpt: "Writing a compelling essay can be challenging, but with the right approach, you can conquer it! This guide provides a step-by-step process to help you structure, develop, and refine your essays.",
      },
      {
        id: 3,
        title: "Boosting Your Vocabulary: Fun and Effective Strategies",
        image: vocabulary,
        excerpt: "Expanding your vocabulary is crucial for effective communication and academic success. Discover fun and engaging strategies to learn new words and improve your language skills.",
      },
    ];

const ArticleCard = () => {
  return (
      <>
      {articles.map((article) => (
      <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="200"
        image={article.image}
        alt={article.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {article.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {article.excerpt}
        </Typography>
        <Button variant="outlined" size="small" sx={{marginTop: 2}}>
          Read More
        </Button>
      </CardContent>
    </Card>
      ))}
    </>
  )
}

export default ArticleCard