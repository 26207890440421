import React from 'react'

const ReadingMaterial = ({ setShowReadingMaterial }) => {

    const back = () => {
        setShowReadingMaterial(false);
    }

  return (
    <div>ReadingMaterial
        <button onClick={back}>Back</button>
    </div>
  )
}

export default ReadingMaterial