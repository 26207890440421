import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCTXvPD9yUxLX4t1LQZk7ifvMVBRu_D6YM",
  authDomain: "tutor-app-8052c.firebaseapp.com",
  projectId: "tutor-app-8052c",
  storageBucket: "tutor-app-8052c.appspot.com",
  messagingSenderId: "391077766815",
  appId: "1:391077766815:web:6b7204e6a4c7e6575c24aa",
  measurementId: "G-KCY5WZQG8X"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);