import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";



const ResourcesCard = ({ image, head, description }) => {
  return (
        <Card sx={{ maxWidth: 345, width: 345 }} style={{display:'flex', flexDirection: 'column', justifyContent:'space-between' }}>
          <CardMedia sx={{ height: 200 }} image={image} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {head}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{fontFamily: 'Roboto'}}>
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">
              Continue Reading
            </Button>
          </CardActions>
        </Card>
  );
};

export default ResourcesCard;
