import React from "react";
import ResourcesCard from "../components/resourcesCard";
import { Typography } from "@mui/material";
import "./resources.css";
import { cardDetails } from "../components/resourcesData";

const Resources = () => {
    return (
        <div>
            <div className="header resourcesPg">
                <div></div>
                <Typography
                    variant="h1"
                    color="white"
                    sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        textTransform: "uppercase",
                    }}
                >
                    Resources
                </Typography>

                <Typography
                    variant="h5"
                    color="white"
                    sx={{
                        fontFamily: "Space Mono",
                    }}
                >
                    This page provides a central hub for all the educational
                    resources offered by The Learning Edge.
                </Typography>
            </div>
            {/* Latest Article
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "50px",
                }}
            >
                <iframe
                    title="Latest Article"
                    src={LatestArticle}
                    width="60%"
                    height="600px"
                    frameborder="0"
                ></iframe>
            </div> */}

            {/* Resources */}
            {/* <Divider sx={{padding:10}}>
                <Chip label="Resources" size="large" />
            </Divider> */}
            <div className="resourcesCards">
                {cardDetails.map((cardDetail) => (
                    <ResourcesCard
                        image={cardDetail.image}
                        head={cardDetail.head}
                        description={cardDetail.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default Resources;
