import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const PodcastCard = ({ title, description, image }) => {
  return (

    <Card sx={{maxWidth: '40vw', borderRadius: '5px', '@media (max-width: 800px)': {
      maxWidth: '90vw',
    },}}>
        <iframe
          title='Top 10 Advanced CSS Responsive Design Concepts You Should Know'
          src='https://www.youtube.com/embed/j0-0UTejJVE'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
          style={{width: '100%', height: '400px', padding:'5px',  border: "none" ,borderRadius: '10px'}}
        />
      <CardContent>
        <Typography variant="h5" component="h2">
          Top 10 Advanced CSS Responsive Design Concepts You Should Know
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'justify'}}>
          Responsive design in CSS is difficult to do well, especially if you only ever use media queries. In this video, I share my top 10 responsive design tips that go beyond just using simple media queries.
        </Typography>
      </CardContent>
    </Card>
  )
}

export default PodcastCard