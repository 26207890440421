import React, { useState } from 'react'
import { Typography, Button, TextField, FormControl, Alert, Snackbar, InputAdornment } from '@mui/material';
import { Add, Link} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { db } from '../firebase/firebase';
import { addDoc, collection } from "firebase/firestore"; 


const Podcasts = ({ setShowPodcast }) => {
  const [addPodcastValues, setAddPodcastValues] = useState({ podcastName: '', detail: '', link: '' });
  const [successAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setAddPodcastValues({ ...addPodcastValues, [name]: value });
    setError(null);
  };

  const addPodcast = async (e) => {
    e.preventDefault();

    if (
      !addPodcastValues.podcastName ||
      !addPodcastValues.detail ||
      !addPodcastValues.link
    ) {
      setError('Please fill in all fields');
      return;
    }

    if (!/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/.test(
      addPodcastValues.link
    )) {
      setError('Please enter a valid URL');
      return;
    }

    try {
      await addDoc(collection(db, "Podcasts"), {
        podcastName: addPodcastValues.podcastName,
        detail: addPodcastValues.detail,
        link: addPodcastValues.link,
      });
      setSuccessAlert(true);
      setAddPodcastValues({ podcastName: '', detail: '', link: '' }); 
    } catch (e) {
      alert("Error adding document: ", e);
    }
  };

  const back = () => {
    setShowPodcast(false);
  }
  return (
    <center><div style={{maxWidth:'80%'}}>
      {successAlert && (
          <Snackbar open={successAlert} autoHideDuration={6000} onClose={() => setSuccessAlert(false)}>
            <Alert severity="success">Podcast added successfully!</Alert>
          </Snackbar>
        )}
      <Typography variant='h1'>Add Podcasts</Typography>
      <FormControl style={{display: 'flex', flexDirection: 'column', gap: '20px', marginBottom:'20px', maxWidth:'70%'}}>
        <TextField label='Podcast Name' variant='outlined' required onChange={handleChanges} id='podcastName' name='podcastName' />
        <TextField label='Detail' variant='outlined' multiline rows={5} required onChange={handleChanges} id='detail'name='detail' />
        <TextField label='Link' variant='outlined'  required onChange={handleChanges} id='link' name='link' 
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link />
            </InputAdornment>
          ),
        }}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <div style={{display: 'flex', justifyContent:'flex-end', gap: '20px'}}>
          <Button variant='contained' onClick={back} startIcon={<ArrowBackIcon />}>Back</Button>
          <Button type='submit' variant='contained' onClick={addPodcast} startIcon={<Add />}>Add Podcast</Button>
        </div>
      </FormControl>
    </div></center>
  )
}

export default Podcasts