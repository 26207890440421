export const podcastDetails = [
      {
            id: 9,
            source: "https://open.spotify.com/embed/episode/0cqneLkNh9pCR7FBw00OHX?utm_source=generator",
      },
      {
            id: 8,
            source: "https://open.spotify.com/embed/episode/1GQYo7GJQIohG2D0jI41Ml?utm_source=generator"
      },
      {
            id: 7,
            source: "https://open.spotify.com/embed/episode/0Mrqzi2bhNMIKDwdMBBeEH?utm_source=generator"
      },
      {
            id: 6,
            source: "https://open.spotify.com/embed/episode/1H2t4CesVVC457C7vyquaq?utm_source=generator"
      },
      {
            id: 5,
            source: "https://open.spotify.com/embed/episode/0fMI4uFLvvF5bEVGRhKd4W?utm_source=generator"
      },
      {
            id: 4,
            source: "https://open.spotify.com/embed/episode/1dtTw5UoA7sZZBIwN88jt8?utm_source=generator"
      },
      {
            id: 3,
            source: "https://open.spotify.com/embed/episode/3gpUjSIWVRYCbgZ2RbuP5V?utm_source=generator"
      },
      {
            id: 2,
            source: "https://open.spotify.com/embed/episode/2hQU6bTkTJJFQJPvvBrt0l?utm_source=generator"
      },
      {
            id: 1,
            source: "https://open.spotify.com/embed/episode/39ysG314nwrRn1gWtfWVdc?utm_source=generator"
      },

]
