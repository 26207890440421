import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import podcast from '../img/PodcastImg.jpg'
import studyMaterials from '../img/StudyMaterials.jpg'
import './DashboardCard.css'


const DashboardCard = ({ setShowPodcast, setShowReadingMaterial }) => {

  const displayAddPodcasts = () => {
    setShowPodcast(true)
    setShowReadingMaterial(false)
  }

  const displayAddReadingMaterials = () => {
    setShowReadingMaterial(true)
    setShowPodcast(false)
  }

  return (
    <div style={{display: 'flex', justifyContent:'space-around', width:'100%', flexWrap:'wrap', gap:'30px'}}>
    <Card sx={{ width: 500 }} className='zoom'>
      <CardActionArea onClick={displayAddPodcasts}>
        <CardMedia
          component="img"
          height="400"
          image={podcast}
          alt="Podcast"
          style={{objectFit:'cover'}}
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div" >
            Add Podcasts
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Click to add Podcasts.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card sx={{ width: 500 }} className='zoom'>
      <CardActionArea onClick={displayAddReadingMaterials}>
        <CardMedia
          component="img"
          height="400"
          image={studyMaterials}
          alt="Podcast"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" >
            Add Reading Materials
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Click to add Reading Materials
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
  )
}

export default DashboardCard