import { React, useState } from "react";
import LogIn from "./login/login";
import Dashboard from "./Dashboard/dashboard";

const Backend = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div>
      {isLoggedIn ? (
        <Dashboard setIsLoggedIn={setIsLoggedIn} />
      ) : (
        <LogIn setIsLoggedIn={setIsLoggedIn} />
      )}
    </div>
  );
};

export default Backend;
