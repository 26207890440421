import React from "react";
import "./home.css";
import { Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ResourcesCard from "../components/resourcesCard";
import PodcastCard from "../components/podcastCard";
import ArticleCard from "../components/articleCard";
import { Link } from "react-router-dom";
import { cardDetails } from "../components/resourcesData";


const theme = createTheme({
    typography: {
        fontFamily: [
            '"Space Mono", monospace',
            "Roboto",
            '"Space Grotesk", sans-serif',
            '"Montserrat", sans-serif',
        ].join(","),
    },
});

const Home = () => {
    return (
        <ThemeProvider theme={theme}>
            <div>
                {/* hero section */}
                <div className="section hero" id="hero">
                    <Typography
                        variant="h5"
                        sx={{
                            fontFamily: "Space Mono",
                            textTransform: "uppercase",
                        }}
                    >
                        Unlocking your academic potential
                    </Typography>
                    <Typography
                        variant="h1"
                        color="white"
                        sx={{ fontFamily: "Montserrat", fontWeight: "500", }}
                    >
                        Learning Edge
                    </Typography>
                    <Button
                        sx={{ fontFamily: "Space Grotesk" }}
                        variant="contained"
                        size="large"
                        color="primary"
                        href="#resources"
                    >
                        Start Learning
                    </Button>
                </div>
                {/* resources section */}
                <div className="section resources" id="resources">
                    <Typography
                        variant="h3"
                        sx={{ fontFamily: "Montserrat", fontWeight: "500" }}
                    >
                        Empower your Learning Journey
                    </Typography>
                    <div className="resourcesCards">
                    {cardDetails.slice(0, 3).map((cardDetail) => (
                        <ResourcesCard image={cardDetail.image}  head={cardDetail.head} description={cardDetail.description}/>
                    ))}
                    </div>
                    <Button
                        sx={{ fontFamily: "Space Grotesk" }}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        <Link to='/Resources' style={{textDecoration:'none', color:'inherit'}}>Explore All Resources</Link>
                        
                    </Button>
                </div>
                {/* Podcast section */}
                <div className="section podcasts" id="podcasts">
                    <Typography
                        variant="h3"
                        sx={{ fontFamily: "Montserrat", fontWeight: "500" }}
                    >
                        Listen to our Podcasts
                    </Typography>
                    <div className="podcastsCard">
                        <PodcastCard />
                        <div className="podcastDescription">
                            <Typography variant="h5" sx={{ textTransform: 'uppercase', alignSelf:'center' }}>
                                Welcome to the Learning Edge Podcast
                            </Typography>
                            <Typography variant="body" sx={{ textAlign: 'justify', fontFamily:'Montserrat' }}>
                                Your one-stop shop for igniting your curiosity
                                and exploring the vast world of knowledge! We
                                delve into diverse topics, from the intricacies
                                of history and science to the practical tips and
                                hacks that empower lifelong learning.
                            </Typography>
                            <Button
                                sx={{ fontFamily: "Space Grotesk" }}
                                variant="contained"
                                size="large"
                                color="primary"
                            >
                                <Link to='/Podcasts' style={{textDecoration:'none', color:'inherit'}}>Explore All Podcasts</Link>
                            </Button>
                        </div>
                    </div>
                    <div></div>
                </div>
                {/* Articles Section */}
                <div className="section articles" id="articles">
                    <Typography
                        variant="h3"
                        sx={{ fontFamily: "Montserrat", fontWeight: "500" }}
                    >
                        Dive Deeper with Expert Insights
                    </Typography>
                    <div className="resourcesCards">
                        <ArticleCard />
                    </div>
                    <Button
                        sx={{ fontFamily: "Space Grotesk" }}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        <Link to='/Articles' style={{textDecoration:'none', color:'inherit'}}>Explore All Articles</Link>
                    </Button>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default Home;
