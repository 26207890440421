// Dashboard.js

import { React, useState } from "react";
import { Button, Typography, CssBaseline } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import DashboardCard from "../../components/DashboardCard";
import Podcasts from "../podcasts";
import ReadingMaterials from "../ReadingMaterial";
import "./dashboard.css";

const Dashboard = () => {
  const [showPodcast, setShowPodcast] = useState();
  const [showReadingMaterial, setShowReadingMaterial] = useState();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {})
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div>
      <CssBaseline />
      {showPodcast ? (
        <Podcasts setShowPodcast={setShowPodcast} />
      ) : showReadingMaterial ? (
        <ReadingMaterials setShowReadingMaterial={setShowReadingMaterial} />
      ) : (
        <center><div style={{display: 'flex', flexDirection:'column', gap: '20px', maxWidth:'80%'}}>
          <Button variant="contained" onClick={handleSignOut} className="logOutBtn" >
            Log Out
          </Button>
          <Typography variant="h1" className="heading">Dashboard</Typography>
          <DashboardCard
            setShowPodcast={setShowPodcast}
            setShowReadingMaterial={setShowReadingMaterial}
          />
        </div></center>
      )}
    </div>
  );
};

export default Dashboard;
