import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../img/Logo.webp';

export default function ButtonAppBar() {
  const isMobile = useMediaQuery('(max-width:800px)');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" style={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar style={{ display: 'flex', columnGap: '50px' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', width: 'fit-content' }}>
              <img src={Logo} alt="Logo" style={{ width: '75px' }} />
              Learning Edge
            </Link>
          </Typography>

          {isMobile ? (
            <>
              <IconButton color="inherit" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}><Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link></MenuItem>
                <MenuItem onClick={handleMenuClose}><Link to='/Resources' style={{ textDecoration: 'none', color: 'inherit' }}>Resources</Link></MenuItem>
                <MenuItem onClick={handleMenuClose}><Link to='/Podcasts' style={{ textDecoration: 'none', color: 'inherit' }}>Podcasts</Link></MenuItem>
                <MenuItem onClick={handleMenuClose}><Link to='/Articles' style={{ textDecoration: 'none', color: 'inherit' }}>Articles</Link></MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button color='inherit'><Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link></Button>
              <Button color='inherit'><Link to='/Resources' style={{ textDecoration: 'none', color: 'inherit' }}>Resources</Link></Button>
              <Button color='inherit'><Link to='/Podcasts' style={{ textDecoration: 'none', color: 'inherit' }}>Podcasts</Link></Button>
              <Button color='inherit'><Link to='/Articles' style={{ textDecoration: 'none', color: 'inherit' }}>Articles</Link></Button>
            </>
          )}

        </Toolbar>
      </AppBar>
    </Box>
  );
}
