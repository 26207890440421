import './App.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Home from './Pages/home';
import Resources from './Pages/Resources';
import Podcasts from './Pages/podcasts';
import Articles from './Pages/Articles';
import Navbar from './components/navbar';
import Backend from './BackPages/Backend';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Resources" element={<Resources />} />
          <Route path="/Podcasts" element={<Podcasts />} />
          <Route path="/Articles" element={<Articles />} />
          <Route path='/admin/' element={<Backend />} />
          <Route path="*" element={<center><h1 style={{marginTop: '100px'}}>Not Found 404</h1></center>} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
